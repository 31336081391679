
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { lightFormat } from "date-fns";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";
import { DateSelector } from "prometheus-synced-ui";

import { ThermometerItem } from "@/domain";

import { IThermometerService } from "@/services";

@Component({
  components: {
    UpdateUi,
    EquipmentBrandAutocomplete,
    DateSelector,
  },
})
export default class ThermometerUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: ThermometerItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get thermometerService() {
    return this.container.resolve<IThermometerService>(S.THERMOMETER_SERVICE);
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;
  validityDate: Date | null = null;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.equipmentType = this.item.equipmentType;
    this.equipmentBrandId = this.item.equipmentBrand.id;
    this.validityDate = this.item.validityDate;
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const updatedThermometer = await this.thermometerService.update(
        this.item.id,
        {
          serialNumber: this.serialNumber!,
          equipmentType: this.equipmentType!,
          equipmentBrandId: this.equipmentBrandId!,
          validityDate: lightFormat(this.validityDate!, "yyyy-MM-dd"),
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", updatedThermometer);
    } finally {
      this.loading = false;
    }
  }
}
